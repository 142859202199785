import { RootProvider } from "@/providers/RootProvider";

import ProjectStages from "./ProjectStages";
import { ProjectSummaryCard } from "./ProjectSummaryCard";

export const ProjectShow = ({
  projectId,
  conversationHtml,
}: {
  projectId: string;
  conversationHtml: string;
}) => {
  return (
    <RootProvider>
      <div className="flex h-full w-full gap-4 bg-gray-50 p-4">
        <div className="flex w-full flex-1 flex-col gap-4">
          <ProjectStages projectId={projectId} />
          <ProjectSummaryCard projectId={projectId} />
        </div>
        <div className="hidden max-w-[450px] md:block">
          {conversationHtml && (
            <div
              className="block h-full overflow-hidden rounded-lg border border-gray-100 bg-white"
              dangerouslySetInnerHTML={{ __html: conversationHtml }}
            />
          )}
        </div>
      </div>
    </RootProvider>
  );
};
