import { LayoutRight } from "@craftworkco/nucleo-icons";

import { Main } from "@/components/layouts/SidebarLayout";
import ProjectEstimates from "@/components/projects/ProjectEstimates";
import { ProjectSummaryCard } from "@/components/projects/ProjectSummaryCard";
import ProjectTasks from "@/components/projects/ProjectTasks";
import Button from "@/components/ui/Button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/Sheet";
import useRouter from "@/hooks/useRouter";

export const CheckinSummary = ({
  conversationHtml,
}: {
  conversationHtml: string;
}) => {
  const { params } = useRouter();
  return (
    <Main className="relative grid grid-cols-1 gap-3 overflow-hidden p-3 md:grid-cols-[6fr_4fr]">
      {!params.projectId && (
        <div className="flex items-center justify-center">
          <span className="text-sm text-gray-500">
            Select a project to view the conversation
          </span>
        </div>
      )}
      {conversationHtml && (
        <div
          className="block h-full overflow-hidden rounded-lg border border-gray-100 bg-white"
          dangerouslySetInnerHTML={{ __html: conversationHtml }}
        />
      )}
      <div className="no-scrollbar hidden flex-col gap-3 overflow-y-auto md:flex">
        {params.projectId && (
          <>
            <ProjectSummaryCard
              projectId={params.projectId}
              orientation="vertical"
            />
            <ProjectTasks projectId={params.projectId} />
            <ProjectEstimates projectId={params.projectId} />
          </>
        )}
      </div>
      <div className="flex md:hidden">
        <Sheet>
          {params.projectId && (
            <SheetTrigger asChild className="absolute right-0 top-0">
              <Button variant="ghost" className="rounded-none border">
                <LayoutRight />
              </Button>
            </SheetTrigger>
          )}
          <SheetContent side="right" className="bg-white">
            <SheetHeader>
              <SheetTitle>Project Summary</SheetTitle>
            </SheetHeader>
            <div className="no-scrollbar flex h-full flex-col gap-3 overflow-y-auto">
              {params.projectId && (
                <>
                  <ProjectSummaryCard
                    projectId={params.projectId}
                    orientation="vertical"
                  />
                  <ProjectTasks projectId={params.projectId} />
                  <ProjectEstimates projectId={params.projectId} />
                </>
              )}
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </Main>
  );
};
