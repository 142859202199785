import { useState } from "react";
import {
  ChevronRight,
  CircleCheck,
  CircleDashed,
  CircleHalfDashedCheck,
  ProgressCircle1Of4,
  ProgressCircle3Of4,
  ProgressCircle4Of4,
  ProgressCircleTwoOf4,
} from "@craftworkco/nucleo-icons";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";

import { clsxm } from "../../utils/clsxm";
import { Checkbox } from "../ui/Checkbox";

type StageSummaryProps = {
  currentState: CurrentState;
  handleActionClick: (action: Action) => void;
};

const StageSummary: React.FC<StageSummaryProps> = ({
  currentState,
  handleActionClick,
}) => {
  const remainingActions = currentState.checkpoint.actions.filter(
    (action) => !action.completed,
  ).length;

  const remainingText =
    remainingActions > 1 ? `+${remainingActions - 1} more` : "";

  return (
    <div
      className={clsxm(
        "flex items-center justify-between gap-2 rounded-lg border p-1.5",
        currentState.stage.colorClass,
        currentState.stage.name !== "Done" && "text-white",
      )}
    >
      <div className="flex w-full items-center gap-2 p-1.5 font-medium text-sm leading-none">
        <currentState.stage.icon />
        <span>{currentState.stage.name}</span>
        <ChevronRight className="opacity-50" />
        <span>{currentState.checkpoint.title}</span>
        <ChevronRight className="opacity-50" />
        <Checkbox
          checked={currentState.action.completed}
          onChange={() => handleActionClick(currentState.action)}
          className="border-white/50"
        />
        <span>{currentState.action.name}</span>
        {remainingText && <span className="opacity-50">{remainingText}</span>}
      </div>
    </div>
  );
};

const getCheckpointCompletion = (checkpoint: Checkpoint) => {
  const completed = checkpoint.actions.filter(
    (action) => action.completed,
  ).length;
  const total = checkpoint.actions.length;
  return `${completed}/${total}`;
};

const getStageCompletion = (stage: Stage) => {
  let totalActions = 0;
  let completedActions = 0;

  stage.checkpoints.forEach((checkpoint) => {
    totalActions += checkpoint.actions.length;
    completedActions += checkpoint.actions.filter(
      (action) => action.completed,
    ).length;
  });

  const percentage =
    totalActions > 0 ? Math.round((completedActions / totalActions) * 100) : 0;
  return `${percentage}%`;
};

const getProgressIcon = (completed: number, total: number) => {
  if (completed === 0) return CircleDashed;
  if (completed === total) return CircleCheck;
  return CircleHalfDashedCheck;
};

type ColumnProps = {
  children: React.ReactNode;
  title: string;
};

const Column: React.FC<ColumnProps> = ({ children, title }) => {
  return (
    <div className="flex flex-col gap-2 p-2">
      <h4 className="text-xs text-gray-500">{title}</h4>
      {children}
    </div>
  );
};

type ListItemProps = {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
};

const ListItem: React.FC<ListItemProps> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <div
      className={clsxm(
        "flex cursor-pointer items-center justify-between gap-2 rounded-md px-2 py-1.5 text-sm",
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const StageDetails = ({
  currentState,
  viewState,
  handleStageClick,
  handleCheckpointClick,
  handleActionClick,
}: {
  currentState: CurrentState;
  viewState: CurrentState;
  handleStageClick: (stage: Stage) => void;
  handleCheckpointClick: (checkpoint: Checkpoint) => void;
  handleActionClick: (action: Action) => void;
}) => {
  return (
    <div className="grid grid-cols-3 divide-x">
      {/* Stages */}
      <Column title="Project Stage">
        {projectWorkflow.map((stage, i) => {
          const completedActions = stage.checkpoints.reduce(
            (sum, checkpoint) =>
              sum +
              checkpoint.actions.filter((action) => action.completed).length,
            0,
          );
          const totalActions = stage.checkpoints.reduce(
            (sum, checkpoint) => sum + checkpoint.actions.length,
            0,
          );
          const ProgressIcon = getProgressIcon(completedActions, totalActions);

          return (
            <ListItem
              key={i}
              className={clsxm(
                stage === viewState.stage ? "bg-white" : "hover:bg-white/50",
              )}
              onClick={() => handleStageClick(stage)}
            >
              <div className="flex items-center gap-2">
                <ProgressIcon className="h-4 w-4" />
                <div>
                  <span>{stage.name}</span>
                  <span className="text-gray-500">
                    {stage === currentState.stage && ": current"}
                    {stage === viewState.stage &&
                      stage !== currentState.stage &&
                      ": viewing"}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-sm text-gray-500">
                  {getStageCompletion(stage)}
                </span>
                {stage === viewState.stage && (
                  <ChevronRight className="h-4 w-4" />
                )}
              </div>
            </ListItem>
          );
        })}
      </Column>

      {/* Checkpoints */}
      <Column title={viewState.stage.name + " Checkpoints"}>
        {viewState.stage.checkpoints.map((checkpoint, i) => {
          const completedActions = checkpoint.actions.filter(
            (action) => action.completed,
          ).length;
          const totalActions = checkpoint.actions.length;
          const ProgressIcon = getProgressIcon(completedActions, totalActions);

          return (
            <ListItem
              key={i}
              className={clsxm(
                checkpoint === viewState.checkpoint
                  ? "bg-white"
                  : "hover:bg-white/50",
              )}
              onClick={() => handleCheckpointClick(checkpoint)}
            >
              <div className="flex items-center gap-2">
                <ProgressIcon className="h-4 w-4" />
                <span>{checkpoint.title}</span>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-sm text-gray-500">
                  {getCheckpointCompletion(checkpoint)}
                </span>
                {checkpoint === viewState.checkpoint && (
                  <ChevronRight className="h-4 w-4" />
                )}
              </div>
            </ListItem>
          );
        })}
      </Column>

      {/* Actions */}
      <Column title={viewState.checkpoint.title + " Actions"}>
        {viewState.checkpoint.actions.map((action, i) => (
          <ListItem key={i} onClick={() => handleActionClick(action)}>
            <div className="flex items-center gap-2">
              <Checkbox
                id={action.name}
                defaultChecked={action.completed}
                disabled={action.completed}
                onChange={() => handleActionClick(action)}
              />
              <label
                htmlFor={action.name}
                className={clsxm(
                  "cursor-pointer",
                  action.completed && "text-gray-500",
                )}
              >
                {action.name}
              </label>
            </div>
            {!action.completed && (
              <span className="cursor-pointer text-sm text-gray-500 underline">
                Skip
              </span>
            )}
          </ListItem>
        ))}
      </Column>
    </div>
  );
};

type CurrentState = {
  stage: Stage;
  checkpoint: Checkpoint;
  action: Action;
};

const getInitialState = (): CurrentState => {
  const currentStage =
    projectWorkflow.find((stage) => stage.current) ?? projectWorkflow[0];
  const currentCheckpoint =
    currentStage?.checkpoints.find((checkpoint) => checkpoint.current) ??
    currentStage?.checkpoints[0];
  const currentAction =
    currentCheckpoint?.actions.find((action) => !action.completed) ??
    currentCheckpoint?.actions[0];

  if (!currentStage || !currentCheckpoint || !currentAction) {
    throw new Error("Invalid workflow state");
  }

  return {
    stage: currentStage,
    checkpoint: currentCheckpoint,
    action: currentAction,
  };
};
const ProjectStages = ({ projectId }: { projectId: string }) => {
  const [currentState, setCurrentState] =
    useState<CurrentState>(getInitialState());
  const [viewState, setViewState] = useState<CurrentState>(currentState);

  console.log(projectId);

  const handleStageClick = (stage: Stage) => {
    if (!stage.checkpoints[0] || !stage.checkpoints[0].actions[0]) {
      throw new Error("Invalid workflow state");
    }

    setViewState({
      stage: stage,
      checkpoint: stage.checkpoints[0],
      action: stage.checkpoints[0].actions[0],
    });
  };

  const handleCheckpointClick = (checkpoint: Checkpoint) => {
    if (!checkpoint.actions[0]) {
      throw new Error("Invalid workflow state");
    }

    setViewState({
      ...viewState,
      checkpoint: checkpoint,
      action: checkpoint.actions[0],
    });
  };

  const handleActionClick = (action: Action) => {
    setCurrentState({
      ...currentState,
      action: action,
    });
  };

  return (
    <Accordion
      type="single"
      collapsible
      defaultValue="1"
      className="rounded-lg border bg-muted"
    >
      <AccordionItem value="1">
        <AccordionTrigger className="w-full p-0">
          <StageSummary
            currentState={currentState}
            handleActionClick={handleActionClick}
          />
        </AccordionTrigger>
        <AccordionContent>
          <StageDetails
            currentState={currentState}
            viewState={viewState}
            handleStageClick={handleStageClick}
            handleCheckpointClick={handleCheckpointClick}
            handleActionClick={handleActionClick}
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default ProjectStages;

type Action = {
  name: string;
  icon: string;
  href?: string;
  completed: boolean;
};

type Checkpoint = {
  title: string;
  actions: Action[];
  current: boolean;
};

type Stage = {
  name: string;
  icon: React.ElementType;
  checkpoints: Checkpoint[];
  colorClass: string;
  current: boolean;
};

const projectWorkflow: Stage[] = [
  {
    name: "Lead",
    icon: ProgressCircle1Of4,
    colorClass: "bg-primary",
    current: true,
    checkpoints: [
      {
        title: "Qualified",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: true,
        }),
      },
      {
        title: "Estimate Ready",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: true,
        }),
      },
      {
        title: "Estimate Approved",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: true,
        }),
      },
      {
        title: "Planning Handoff",
        current: true,
        actions: [
          { name: "Deposit Paid", icon: "check", completed: true },
          { name: "Dates Requested", icon: "check", completed: true },
          { name: "Dates Confirmed", icon: "check", completed: false },
          { name: "Planning Handoff", icon: "check", completed: false },
        ],
      },
    ],
  },
  {
    name: "Setup",
    icon: ProgressCircleTwoOf4,
    colorClass: "bg-purple-900",
    current: false,
    checkpoints: [
      {
        title: "Customer Onboard",
        current: true,
        actions: [
          { name: "Color Selection", icon: "check", completed: false },
          { name: "Preferences", icon: "check", completed: false },
          { name: "Additional Photos", icon: "check", completed: false },
        ],
      },
      {
        title: "Project Prep",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: false,
        }),
      },
      {
        title: "Crew Prep",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: false,
        }),
      },
      {
        title: "Production Handoff",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: false,
        }),
      },
    ],
  },
  {
    name: "Delivery",
    icon: ProgressCircle3Of4,
    colorClass: "bg-pink-600",
    current: false,
    checkpoints: [
      {
        title: "Kickoff",
        current: true,
        actions: [
          { name: "Supplies Delivered", icon: "check", completed: false },
          { name: "Customer Checklist", icon: "check", completed: false },
          { name: "Areas Prepped", icon: "check", completed: false },
        ],
      },
      {
        title: "Midpoint Check",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: false,
        }),
      },
      {
        title: "Production Finished",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: false,
        }),
      },
      {
        title: "CX Handoff",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: false,
        }),
      },
    ],
  },
  {
    name: "Done",
    icon: ProgressCircle4Of4,
    colorClass: "bg-white",
    current: false,
    checkpoints: [
      {
        title: "Project Review",
        current: true,
        actions: [
          { name: "Final Walkthrough", icon: "check", completed: false },
          { name: "Supplies Recovered", icon: "check", completed: false },
          { name: "Final Payment Collected", icon: "check", completed: false },
          { name: "Post Mortem", icon: "check", completed: false },
        ],
      },
      {
        title: "Customer Satisfaction",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: false,
        }),
      },
      {
        title: "Project Complete",
        current: false,
        actions: Array(4).fill({
          name: "Action",
          icon: "check",
          completed: false,
        }),
      },
    ],
  },
];
