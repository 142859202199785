import type { EstimateRecord } from "@/types";
import type { ConstructReactQueryConfig } from "@/utils/constructReactQuery";
import { constructReactQuery } from "@/utils/constructReactQuery";

type EstimatesQueryProps = {
  status?: string[];
  managerId?: string;
};

export const useEstimatesQuery = (
  { status, managerId }: EstimatesQueryProps,
  config?: ConstructReactQueryConfig<EstimateRecord[]>,
) => {
  const searchParams = new URLSearchParams();

  if (status) {
    status.forEach((s) => searchParams.append("status[]", s));
  }

  if (managerId) {
    searchParams.append("manager_id", managerId);
  }

  const url = `/api/v1/estimates?${searchParams.toString()}`;
  return constructReactQuery<EstimateRecord[]>(url, {
    ...config,
    queryKey: ["estimates", status, managerId],
  });
};
