import { useState } from "react";
import { format } from "date-fns";
import { toast } from "sonner";

import { useApiMutation } from "@/api/openapi";
import { queryClient } from "@/application";
import { useAccountManagers } from "@/hooks/useAccountManagers";
import type { GetSuccessResponse } from "@/types/api/util";
import { clsxm } from "@/utils/clsxm";

import CraftworkerAvatar from "../craftworkers/CraftworkerAvatar";
import { Checkbox } from "../ui/Checkbox";

type CheckedState = boolean | "indeterminate";

const ProjectTaskItem = ({
  task,
}: {
  task: GetSuccessResponse<"/api/v2/tasks/{id}">;
}) => {
  const [checked, setChecked] = useState<CheckedState>(
    Boolean(task.completedAt),
  );
  const { data: accountManagerData } = useAccountManagers();
  const accountManagers = accountManagerData?.data;

  const { mutate: updateTask } = useApiMutation(
    "/api/v2/tasks/{id}",
    task.prefixId,
    "put",
    {
      onSuccess: () => {
        toast.success(`Task updated`);
      },
      onError: () => {
        toast.error(`Failed to update task`);
        setChecked(!checked);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["/api/v2/tasks"] });
      },
    },
  );

  return (
    <div
      key={task.prefixId}
      className="flex items-center justify-between gap-2 rounded-md p-2"
    >
      <div className="items-top flex gap-2">
        <Checkbox
          id={task.prefixId}
          checked={checked}
          onCheckedChange={(checked) => {
            setChecked(checked);
            const isChecked = checked !== "indeterminate" ? checked : false;
            updateTask({
              completedAt: isChecked ? new Date().toISOString() : null,
            });
          }}
        />
        <div className="grid gap-1.5 leading-none">
          <label
            htmlFor={task.prefixId}
            className={clsxm(
              "font-sans",
              checked && "text-gray-500 line-through",
            )}
          >
            {task.name}
          </label>
          {task.description && (
            <p className="text-xs text-muted-foreground">{task.description}</p>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        {format(new Date(task.dueAt), "M/d")}
        <CraftworkerAvatar
          name={task.assigneeName ?? ""}
          avatarUrl={
            accountManagers?.find(
              (am) => am.userPrefixId === task.assigneePrefixId,
            )?.avatarUrl ?? ""
          }
          prefixId={task.assigneeCraftworkerPrefixId ?? ""}
        />
      </div>
    </div>
  );
};

export default ProjectTaskItem;
