import { Badge } from "@/components/ui/Badge";
import type { ProjectType } from "@/types";
import { ProjectTypes } from "@/types";
import { clsxm } from "@/utils/clsxm";

type PaintableCountBadgeProps = {
  count: number;
  type: ProjectType;
  className?: string;
};

export const PaintableCountBadge: React.FC<PaintableCountBadgeProps> = ({
  count,
  type,
  className,
}) => {
  let classes = "";
  switch (type) {
    case ProjectTypes.Interior:
      classes = "bg-interior";
      break;
    case ProjectTypes.Cabinets:
      classes = "bg-cabinets";
      break;
    case ProjectTypes.Exterior:
      classes = "bg-exterior";
      break;
    case ProjectTypes.Specialty:
      classes = "bg-specialty";
      break;
    default:
      classes = "bg-gray-500 text-black";
      break;
  }

  if (count === 0) return null;
  return (
    <Badge
      className={clsxm(
        "w-max max-w-[20ch] truncate text-white",
        classes,
        className,
      )}
    >
      {count} {type}
      {count > 1 ? " Areas" : " Area"}
    </Badge>
  );
};
