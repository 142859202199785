import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["badge", "record", "menuItem"];
  static values = { settings: Object };
  debounceTimer;
  params;

  // Executed when the controller first connects
  connect() {
    // Ensure we wait for next tick to allow URL to be fully available
    setTimeout(() => {
      this.initializeParams();
      this.updateAllBadges();
      // Add debug logging
      console.log("After initialization, params:", this.params.toString());
    }, 0);
  }

  recordTargetConnected(target) {
    this.initializeParams();
    if (this.params.toString()) {
      this.initializeRecordBadges(target);
    } else {
      // If params are still empty, retry once
      setTimeout(() => {
        this.initializeParams();
        this.initializeRecordBadges(target);
      }, 100);
    }
  }

  // Add this new method
  initializeParams() {
    if (
      !this.params ||
      this.params.toString() !== window.location.search.substring(1)
    ) {
      this.params = new URLSearchParams(window.location.search);
    }
  }

  // Show or hide badges depending on the selected display properties
  initializeRecordBadges(record) {
    const badges = record.querySelectorAll("[data-badge]");
    const displayProps = this.params.getAll("display_properties[]");

    badges.forEach((badge) => {
      const value = badge.dataset.value;
      if (displayProps.includes(value)) {
        badge.classList.remove("hidden");
      } else {
        badge.classList.add("hidden");
      }
    });
  }

  // Rerender the display badges and menu items
  updateAllBadges() {
    this.recordTargets.forEach((record) => {
      this.initializeRecordBadges(record);
    });
    this.menuItemTargets.forEach((item) => {
      this.updateMenuItem(item);
    });
  }

  // Whether a menu item is selected or not
  isActive(item) {
    if (item.dataset.key.includes("[]")) {
      // working with an array, probably display_properties
      // Check to see if the item's value is in the array.
      return this.params.getAll(item.dataset.key).includes(item.dataset.value);
    } else {
      // working with a single value
      return this.params.has(item.dataset.key);
    }
  }

  // Update the menu item's appearance based on whether it is selected or not
  // client side. Usually this is set when we render from the server, but
  // it's useful when we do client side only show and hide of display properties.
  updateMenuItem(item) {
    const icon = item.querySelector("[data-display-toggle-icon]");

    if (this.isActive(item)) {
      item.classList.remove("opacity-50");
      if (icon) {
        icon.classList.add("bg-plum", "text-white");
      }
    } else {
      item.classList.add("opacity-50");
      if (icon) {
        icon.classList.remove("bg-plum", "text-white");
      }
    }
  }

  // Helper function to debounce the callback to update the user's settings
  debounce(callback, delay = 600) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(callback, delay);
  }

  sort(event) {
    const key = "direction";
    const value = event.currentTarget.dataset.value;

    this.params.set(key, value);
    this.sendPostRequest();
  }

  set(event) {
    const key = event.currentTarget.dataset.key;
    const value = event.currentTarget.dataset.value;
    this.params.set(key, value);

    this.updateAllBadges();
    this.sendPostRequest();
  }

  // toggle, insert, and remove are for working with multiple value selections
  // like display_properties[]
  toggle(event) {
    if (this.isActive(event.currentTarget)) {
      this.remove(event);
    } else {
      this.insert(event);
    }
  }

  // When working with lists of values for a given key, we need to append:
  insert(event) {
    const key = event.currentTarget.dataset.key;
    const value = event.currentTarget.dataset.value;
    this.params.append(key, value);

    this.updateAllBadges();
    this.debounce(() => this.sendPostRequest(false));
  }

  // When working with lists of values for a given key, we need to remove a single value
  // from the list for that key and then set the key to the remaining values:
  remove(event) {
    const key = event.currentTarget.dataset.key;
    const value = event.currentTarget.dataset.value;
    const values = this.params.getAll(key);
    const newValues = values.filter((v) => v !== value);
    this.params.delete(key);
    newValues.forEach((v) => this.params.append(key, v));

    this.updateAllBadges();
    this.debounce(() => this.sendPostRequest(false));
  }

  clear(event) {
    const key = event.currentTarget.dataset.key;
    this.params.delete(key);

    this.updateAllBadges();
    this.sendPostRequest();
  }

  async sendPostRequest(follow = true) {
    const path = window.location.pathname;
    const url = `${path}/apply?${this.params.toString()}`;

    try {
      const requestArgs = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
      };

      if (follow) {
        requestArgs.redirect = "follow";
      } else {
        requestArgs.redirect = "manual";
      }

      const response = await fetch(url, requestArgs);

      if (follow) {
        window.location.href = response.url;
      } else {
        window.history.pushState({}, "", `${path}?${this.params.toString()}`);
      }
    } catch (error) {
      console.error("Error sending POST request:", error);
    }
  }
}
