import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "shiftId",
    "locationId",
    "eventId",
    "startedAt",
    "eventsSelect",
  ];

  connect() {
    this.form = this.element.closest("form");
    if (this.form) {
      this.form.addEventListener(
        "turbo:submit-end",
        this.handleFormSubmit.bind(this),
      );
    } else {
      console.error("No form found for element:", this.element);
    }

    this.startedAtTarget.addEventListener(
      "change",
      this.updateEvents.bind(this),
    );
  }

  updateShiftAndLocation(event) {
    const [locationId, eventId, shiftId] = event.target.value.split("-");
    this.locationIdTarget.value = locationId;
    this.shiftIdTarget.value = shiftId;
    this.eventIdTarget.value = eventId;
  }

  async updateEvents() {
    const startedAt = this.startedAtTarget.value;

    if (!startedAt || isNaN(new Date(startedAt).getTime())) return;

    try {
      const response = await fetch(
        `/timesheets/events?started_at=${startedAt}`,
        {
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
              ?.content,
          },
        },
      );
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const events = await response.json();
      this.updateEventsSelect(events);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }

  updateEventsSelect(events) {
    // Find the Events optgroup
    const eventsOptgroup = this.eventsSelectTarget.querySelector(
      'optgroup[label="Events"]',
    );
    if (!eventsOptgroup) return;

    // Update only the Events optgroup content
    eventsOptgroup.innerHTML = events
      .map(
        (event) => `
      <option value="${event.location_id}-${event.id}-">
        ${
          event.project
            ? `${event.project.account.name} - ${event.project.project_type} - `
            : `${event.location.display_name} - `
        }
        ${new Date(event.starts_at).toLocaleDateString("en-US", { weekday: "short" })} ${new Date(event.starts_at).getMonth() + 1}/${new Date(event.starts_at).getDate()} to
        ${new Date(event.ends_at).toLocaleDateString("en-US", { weekday: "short" })} ${new Date(event.ends_at).getMonth() + 1}/${new Date(event.ends_at).getDate()}
      </option>
    `,
      )
      .join("");
  }

  handleFormSubmit(event) {
    const { success } = event.detail;
    if (success) {
      try {
        if (window.queryClient) {
          window.queryClient.invalidateQueries({
            queryKey: ["/api/v1/calendar_events"],
          });
        } else {
          console.error("QueryClient not found on window");
        }
      } catch (e) {
        console.error("Error invalidating query:", e);
      }
    } else {
      console.error("Form submission failed");
    }
  }
}
