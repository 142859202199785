import type { Resource } from "@/types";
import type { ConstructReactQueryConfig } from "@/utils/constructReactQuery";
import { constructReactQuery } from "@/utils/constructReactQuery";

type ResourcesQueryProps = {
  start: string;
  end: string;
};

export const useResourcesQuery = (
  { start, end }: ResourcesQueryProps,
  config?: ConstructReactQueryConfig<Resource[]>,
) => {
  const url = `/api/calendars/projects?cabinet_shop=true&timeoff=true&&start=${start}&end=${end}`;
  return constructReactQuery<Resource[]>(url, config);
};
