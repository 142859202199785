import { useEffect, useState } from "react";
import Map, { Layer, Marker, Source } from "react-map-gl";

type ToolLocation = {
  id: string;
  lat: number;
  lng: number;
  locatedAt: string;
};

type ServiceAreaMapProps = {
  style?: React.CSSProperties;
  zoom?: number;
  start: string;
  end: string;
  mapboxToken: string;
};

export const LocationHistoryMap: React.FC<ServiceAreaMapProps> = ({
  style,
  zoom = 8.75,
  mapboxToken,
}) => {
  const [locations, setLocations] = useState<ToolLocation[]>([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const toolId = window.location.pathname.split("/").pop();

    const params = {
      date: urlParams.get("date") || undefined,
      view: urlParams.get("view") || undefined,
      tool: toolId,
    };

    // filter undefined values out of params
    const filteredParams = JSON.parse(JSON.stringify(params));

    const query = new URLSearchParams(filteredParams).toString();

    fetch(`/api/v1/tool_locations?${query}`)
      .then((response) => response.json())
      .then((data) => {
        setLocations(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLocations([]);
      });
  }, [window.location.search]);

  // Convert locations to GeoJSON LineString
  const pathData = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: locations.map((loc) => [loc.lng, loc.lat]),
    },
  };

  const firstLocation = locations?.[0];
  const lastLocation = locations?.[locations.length - 1];

  return (
    <div className="relative inset-0 h-full overflow-hidden">
      <Map
        style={{
          width: "100%",
          ...style,
          height: "100%",
        }}
        initialViewState={{
          bounds: [
            [-81.079389, 35.577275],
            [-80.552366, 35.001212],
          ],
          latitude: 35.2892435,
          longitude: -80.8158775,
          zoom,
        }}
        mapStyle="mapbox://styles/craftworkmike/clj4jgqts00rf01r1g28p9q9c"
        mapboxAccessToken={mapboxToken}
      >
        <Source id="path-data" type="geojson" data={pathData}>
          <Layer
            id="path-layer"
            type="line"
            paint={{
              "line-color": "#4A90E2",
              "line-width": 3,
              "line-opacity": 0.8,
            }}
          />
        </Source>
        <>
          {firstLocation && (
            <Marker
              longitude={firstLocation.lng}
              latitude={firstLocation.lat}
              color="#22C55E"
            />
          )}
          {lastLocation && (
            <Marker
              longitude={lastLocation.lng}
              latitude={lastLocation.lat}
              color="#EF4444"
            />
          )}
        </>
      </Map>
    </div>
  );
};

export default LocationHistoryMap;
