import type { WeatherReport } from "@/types";
import type { ConstructReactQueryConfig } from "@/utils/constructReactQuery";
import { constructReactQuery } from "@/utils/constructReactQuery";

type WeatherDaysQueryProps = {
  start: string;
  end: string;
};

export const useWeatherDaysQuery = (
  { start, end }: WeatherDaysQueryProps,
  config?: ConstructReactQueryConfig<WeatherReport[]>,
) => {
  const url = `/api/v1/weather_days?start=${start}&end=${end}`;
  return constructReactQuery<WeatherReport[]>(url, {
    refetchInterval: Infinity,
    ...config,
  });
};
