import { clsxm } from "@/utils/clsxm";

type ListGroupLabelProps = {
  label: string;
  icon?: React.ReactNode;
  className?: string;
};

export const ListGroupLabel: React.FC<ListGroupLabelProps> = ({
  label,
  icon,
  className,
}) => {
  const humanizedLabel = label
    .replace("_", " ")
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase());
  return (
    <li
      className={clsxm(
        "sticky top-0 flex items-center justify-between bg-gray-50 px-4 py-2 font-bold text-gray-500",
        className,
      )}
    >
      {humanizedLabel}
      {icon}
    </li>
  );
};
