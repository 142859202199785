import { Fragment } from "react";
import { Bars2Icon, Bars3Icon, Bars4Icon } from "@heroicons/react/16/solid";
import { isWithinInterval } from "date-fns";

import { Switch } from "@/components/ui/Switch";
import { useCalendarSettings } from "@/hooks/useCalendarSettings";
import type { CalendarViewState } from "@/hooks/useCalendarSettings";
import useRouter from "@/hooks/useRouter";
import { useStaffingCalendarData } from "@/hooks/useStaffingCalendarData";
import { RootProvider } from "@/providers/RootProvider";
import type {
  CraftworkersAvailabilityDay,
  Resource,
  WeatherReport,
} from "@/types";
import Button from "@/ui/Button";
import { clsxm } from "@/utils/clsxm";
import { formatDate } from "@/utils/dateFormatter";
import { formatDay, getDaysInRange, getWeekStartAndEnd } from "@/utils/dates";

import { CalendarControls } from "../navigation/CalendarControls";
import CollatedEvent from "./CollatedEvent";
import { CraftworkerAvailability } from "./CraftworkerAvailability";
import { ResourceLabel } from "./ResourceLabel";

type CalendarNavProps = {
  unpublishedShiftCount?: number;
};

const CalendarNav = ({ unpublishedShiftCount }: CalendarNavProps) => {
  const { calendarSettings, toggleWeekends, setStyle, goToToday } =
    useCalendarSettings({
      calendarName: "staffing-calendar",
      defaultViewState,
    });
  const { startDate, endDate, showWeekends } = calendarSettings;
  const { params } = useRouter();

  const isTodayInView = isWithinInterval(new Date(), {
    start: startDate,
    end: endDate,
  });

  return (
    <header className="max-w-screen flex flex-col items-center justify-between gap-2 py-2 lg:flex-row">
      <div className="flex w-full items-center justify-between gap-2">
        {/* Left Side */}
        <div className="flex items-center gap-2">
          <Button
            variant={isTodayInView ? "primary" : "secondary"}
            onClick={goToToday}
          >
            <span className="hidden md:block">Today</span>
            <span className="md:hidden">T</span>
          </Button>
          <div className="hidden items-center gap-2 text-sm md:flex">
            <Switch checked={showWeekends} onCheckedChange={toggleWeekends} />
            <span>Weekends</span>
          </div>
          <div className="flex items-center gap-2 text-sm md:hidden">
            <Switch checked={showWeekends} onCheckedChange={toggleWeekends} />
            <span>Wknds</span>
          </div>
        </div>

        {/* Middle */}
        <CalendarControls
          calendarName="staffing-calendar"
          defaultViewSize="week"
          allowedViewSizes={["week"]}
          className="hidden lg:flex"
        />

        {/* Right Side */}
        <div className="flex items-center gap-2">
          <div className="flex items-center">
            <Button
              variant="ghost"
              onClick={() => setStyle("compact")}
              className={clsxm(
                "rounded-none rounded-l border",
                params?.style === "compact" && "bg-gray-100",
              )}
              data-controller="tooltip"
              data-tippy-content="Compact View"
            >
              <Bars2Icon className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              onClick={() => setStyle("default")}
              className={clsxm(
                "rounded-none border-b border-t",
                params?.style === "default" && "bg-gray-100",
              )}
              data-controller="tooltip"
              data-tippy-content="Default View"
            >
              <Bars3Icon className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              onClick={() => setStyle("detailed")}
              className={clsxm(
                "rounded-none rounded-r border",
                params?.style === "detailed" && "bg-gray-100",
              )}
              data-controller="tooltip"
              data-tippy-content="Detailed View"
            >
              <Bars4Icon className="h-4 w-4" />
            </Button>
          </div>
          <Button
            variant="secondary"
            onClick={() => {
              window.location.href = `/shifts/in_range?modal=true&starts_at=${startDate}&ends_at=${endDate}`;
            }}
            data-turbo-frame="modal"
            disabled={!unpublishedShiftCount || unpublishedShiftCount === 0}
          >
            <span className="hidden md:block">
              {unpublishedShiftCount && unpublishedShiftCount > 0
                ? `Publish ${unpublishedShiftCount} Shifts`
                : `All shifts published`}
            </span>
            <span className="md:hidden">Publish</span>
          </Button>
        </div>
      </div>

      <CalendarControls
        calendarName="staffing-calendar"
        defaultViewSize="week"
        allowedViewSizes={["week"]}
        className="flex w-full items-center justify-between gap-2 lg:hidden"
      />
    </header>
  );
};

type CalendarGridContainerProps = {
  children: React.ReactNode;
  days: Date[];
  classNames?: string;
  compact: boolean;
};

const CalendarGridContainer = ({
  children,
  days,
  classNames,
  compact,
}: CalendarGridContainerProps) => (
  <div
    className={classNames}
    style={{
      display: "grid",
      gridTemplateColumns: `minmax(${compact ? "100px, 1fr" : "150px, 1.2fr"}) repeat(${days.length}, minmax(180px, 1fr))`,
    }}
  >
    {children}
  </div>
);

type DateCellProps = {
  date: Date;
  weather?: WeatherReport;
};

const DateCell = ({ date, weather }: DateCellProps) => {
  const dateStr = date.toISOString();

  const DayOfWeek = formatDate(dateStr, { weekday: "short" });
  const DayOfMonth = formatDate(dateStr, { day: "numeric" });

  return (
    <div className="flex justify-between p-2">
      <div>{DayOfWeek}</div>
      {weather && weather.icon && (
        <img
          src={weather?.icon?.url}
          data-controller="tooltip"
          data-tippy-content={`${weather.tempMin}° - ${weather.tempMax}°`}
          className="h-6 w-6"
        />
      )}
      {weather && !weather.icon && (
        <div>{`${weather.tempMin}° - ${weather.tempMax}°`}</div>
      )}
      <div>{DayOfMonth}</div>
    </div>
  );
};

const defaultViewState: CalendarViewState = {
  viewSize: "week",
  showWeekends: false,
  style: "default",
  ...getWeekStartAndEnd(new Date().toISOString(), false),
};

type EventsByLocationAndDay = Record<string, Record<string, CollatedEvent[]>>;

const Calendar = () => {
  const { calendarSettings } = useCalendarSettings({
    calendarName: "staffing-calendar",
    defaultViewState,
  });

  const { startDate, endDate } = calendarSettings;

  const {
    calendarEvents: events,
    resources,
    craftworkersAvailability,
    craftworkers,
    weatherDays,
  } = useStaffingCalendarData({
    startDate,
    endDate,
  });

  const { params } = useRouter();

  const productionLeads = craftworkers?.filter((cw) => cw.isProductionLead);

  const days = getDaysInRange(
    calendarSettings.startDate,
    calendarSettings.endDate,
  );

  const resourcesUniqByLocation = resources?.reduce(
    (acc: Record<string, Resource>, resource) => {
      if (!acc[resource.resourceId]) {
        acc[resource.resourceId] = resource;
      }
      return acc;
    },
    {},
  );

  const eventsByLocationAndDay = events?.reduce<EventsByLocationAndDay>(
    (acc, event) => {
      const eventStartDay = formatDay(event.start);

      // Ensure the resourceId exists in the accumulator
      if (!acc[event.resourceId]) {
        acc[event.resourceId] = {}; // Initialize as an empty object
      }

      // Initialize the array for the specific day and location if it doesn't exist
      if (!acc[event.resourceId]?.[eventStartDay]) {
        Object.assign(acc, {
          [event.resourceId]: {
            ...acc[event.resourceId],
            [eventStartDay]: [],
          },
        });
      }
      // Push the event into the array for the specific day and location
      acc[event.resourceId]?.[eventStartDay]?.push({
        ...event,
        eventType: event.projectType,
        shifts: event.shifts.map((shift) => ({
          ...shift,
          craftworker: craftworkers?.find(
            (craftworker) => craftworker.id === shift.craftworkerId,
          ),
          timesheets: shift.timesheets.map((timesheet) => ({
            ...timesheet,
            craftworker: craftworkers?.find(
              (craftworker) => craftworker.id === timesheet.craftworkerId,
            ),
          })),
        })),
      });

      return acc;
    },
    {},
  );

  const availabilityDays = Object.entries(
    craftworkersAvailability || {},
  ).reduce<Record<string, CraftworkersAvailabilityDay>>(
    (acc, [day, availability]) => {
      acc[day] = {
        ...availability,
        availableCraftworkers: availability.availableCraftworkerIds
          .map(
            ({ id }) =>
              craftworkers?.find((craftworker) => craftworker.id === id) ||
              null,
          )
          .filter((cw): cw is (typeof craftworkers)[number] => cw !== null),
      };
      return acc;
    },
    {} as Record<string, CraftworkersAvailabilityDay>,
  );

  const weather = weatherDays.reduce(
    (acc: Record<string, WeatherReport>, weatherDay) => {
      acc[weatherDay.day] = weatherDay;
      return acc;
    },
    {},
  );

  const unpublishedShiftCount = events?.reduce((acc, event) => {
    const unpublishedShifts = event.shifts.filter((shift) => !shift.published);
    return acc + unpublishedShifts.length;
  }, 0);

  return (
    <>
      <CalendarNav unpublishedShiftCount={unpublishedShiftCount} />
      <div className="no-scrollbar grid-[auto_1fr] mb-2 grid h-fit w-full overflow-auto rounded-md border border-gray-100">
        {/* Header Row */}
        <CalendarGridContainer
          compact={params?.style === "compact"}
          days={days}
          classNames="sticky top-0 bg-white z-20 rounded-t"
        >
          <div className="sticky left-0 top-0 z-30 rounded-tl border-b border-r bg-white p-2 font-bold">
            Resources
          </div>
          {days.map((date, i) => (
            <div
              className={clsxm(
                "divide-y border-b border-r font-bold",
                i === days.length - 1 && "border-r-0",
              )}
              key={i}
            >
              <DateCell date={date} weather={weather?.[formatDay(date)]} />
              {availabilityDays[formatDay(date)] !== undefined && (
                <CraftworkerAvailability
                  craftworkersAvailabilityDay={
                    {
                      ...availabilityDays[formatDay(date)],
                      date: formatDay(date),
                    } as CraftworkersAvailabilityDay
                  }
                />
              )}
            </div>
          ))}
        </CalendarGridContainer>

        {/* Resource Rows */}
        <CalendarGridContainer
          compact={params?.style === "compact"}
          days={days}
          classNames="no-scrollbar max-h-full"
        >
          {resourcesUniqByLocation &&
            Object.values(resourcesUniqByLocation).map((resource) => {
              return (
                <Fragment key={resource.resourceId}>
                  <ResourceLabel
                    resource={resource}
                    productionLeads={productionLeads}
                    style={params?.style ?? "default"}
                  />
                  {days.map((day, index) => {
                    const eventsForDay =
                      eventsByLocationAndDay?.[resource.resourceId]?.[
                        formatDay(day)
                      ];

                    return (
                      <div
                        key={index}
                        className={clsxm(
                          "flex flex-col divide-y border-b border-r bg-gray-50",
                          index === days.length - 1 && "border-r-0",
                          formatDay(day) === formatDay(new Date()) &&
                            "bg-gray-100",
                        )}
                      >
                        {eventsForDay?.map((event) => (
                          <div
                            key={event.id}
                            id={event.id}
                            className="flex-grow"
                          >
                            <CollatedEvent
                              event={event}
                              style={params?.style ?? "default"}
                            />
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </Fragment>
              );
            })}
        </CalendarGridContainer>
      </div>
    </>
  );
};

export const StaffingCalendar = () => {
  return (
    <RootProvider>
      <Calendar />
    </RootProvider>
  );
};
