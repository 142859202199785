import React from "react";

import useRouter from "@/hooks/useRouter";
import type { EstimateRecord } from "@/types";

import { EstimatesListGroupLabel } from "./EstimatesListGroupLabel";
import {
  EstimateListItem,
  EstimateListItemSkeleton,
} from "./EstimatesListItem";

const NoGroupResults = ({ status }: { status: string }) => (
  <div className="p-4 text-center text-gray-500">
    No estimates found with status {`"${status}"`}
  </div>
);

const NoResults = () => (
  <div className="p-4 text-center text-gray-500">No estimates found</div>
);

type EstimatesListProps = {
  estimates: EstimateRecord[];
  isLoading: boolean;
};

export const EstimatesList: React.FC<EstimatesListProps> = ({
  estimates = [],
}) => {
  const { updateParam, params } = useRouter();
  const selectedEstimateId = params.estimateId;

  if (estimates.length === 0) {
    return <NoResults />;
  }

  const estimateRecordsGropupedByStatus =
    estimates?.reduce<Record<string, EstimateRecord[]>>((groups, estimate) => {
      const { status } = estimate;
      if (!groups[status]) {
        groups[status] = [];
      }
      groups[status].push(estimate);
      return groups;
    }, {}) ?? {};

  const handleSelect = (id: string) => {
    updateParam("estimateId", id);
  };

  return (
    <ul className="divide-y">
      {Object.entries(estimateRecordsGropupedByStatus).map(
        ([status, groupEstimates]) => (
          <React.Fragment key={status}>
            <EstimatesListGroupLabel label={status} />
            {groupEstimates.length > 0 ? (
              groupEstimates.map((estimate) => (
                <EstimateListItem
                  key={estimate.id}
                  estimate={estimate}
                  onSelect={handleSelect}
                  isSelected={selectedEstimateId === estimate.id.toString()}
                />
              ))
            ) : (
              <NoGroupResults status={status} />
            )}
          </React.Fragment>
        ),
      )}
    </ul>
  );
};

export const EstimatesListSkeleton = () => {
  const skeletons = Array.from({ length: 10 }, (_, index) => (
    <EstimateListItemSkeleton key={index} />
  ));
  return <ul className="space-y-2 p-2">{skeletons}</ul>;
};
