// @ts-nocheck
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];

  connect() {
    if (this.element.dataset.tabsActiveTabClass) {
      this.activeTabClasses =
        this.element.dataset.tabsActiveTabClass.split(" ");
      this.hasActiveTabClass = true;
    } else {
      this.hasActiveTabClass = false;
    }

    if (this.element.dataset.tabsInactiveTabClass) {
      this.inactiveTabClasses =
        this.element.dataset.tabsInactiveTabClass.split(" ");
      this.hasInactiveTabClass = true;
    } else {
      this.hasInactiveTabClass = false;
    }

    // Fetch the last selected tab from local storage
    const lastSelectedTab = sessionStorage.getItem("lastSelectedTab");

    if (lastSelectedTab) {
      this.showTab(lastSelectedTab);
    } else {
      this.showTab(this.tabTargets[0].dataset.tabName);
    }
  }

  change(event) {
    event.preventDefault();
    const tabName = event.currentTarget.dataset.tabName;
    this.showTab(tabName);

    // Store the selected tab in local storage
    sessionStorage.setItem("lastSelectedTab", tabName);
  }

  showTab(tabName) {
    this.panelTargets.forEach((panel, index) => {
      const tab = this.tabTargets[index];

      if (panel.id === tabName) {
        panel.classList.remove("hidden");
        if (this.hasInactiveTabClass)
          tab?.classList?.remove(...this.inactiveTabClasses);
        if (this.hasActiveTabClass)
          tab?.classList?.add(...this.activeTabClasses);
      } else {
        panel.classList.add("hidden");
        if (this.hasActiveTabClass)
          tab?.classList?.remove(...this.activeTabClasses);
        if (this.hasInactiveTabClass)
          tab?.classList?.add(...this.inactiveTabClasses);
      }
    });
  }
}
