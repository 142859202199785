import type {
  InfiniteData,
  MutationOptions,
  QueryKey,
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import type {
  DeleteSuccessResponse,
  GetHeaders,
  GetParams,
  GetSuccessResponse,
  PatchBody,
  PatchSuccessResponse,
  PostBody,
  PostSuccessResponse,
  PutBody,
  PutSuccessResponse,
} from "@/types/api/util";
import type { paths } from "@/types/api/v2/schema";

import serializeParams from "./serializer";

const getBaseUrl = () => {
  return window.location.origin;
};

export type GetOptions<Url extends keyof paths> = {
  id?: string | number;
  params?: GetParams<Url>;
  headers?: GetHeaders<Url>;
};

const getFetch = async <Url extends keyof paths>(
  url: Url,
  options?: GetOptions<Url>,
): Promise<GetSuccessResponse<Url>> => {
  const baseUrl = getBaseUrl();
  let apiUrl = `${baseUrl}${url}`;
  if (options?.id) {
    apiUrl = apiUrl.replace("{id}", options.id.toString());
  }
  const queryString = serializeParams(options?.params).toString();
  if (queryString) {
    apiUrl = `${apiUrl}?${queryString}`;
  }

  try {
    const rawResult = await fetch(apiUrl);
    if (!rawResult.ok) {
      throw new Error(rawResult.statusText);
    }
    const result = (await rawResult.json()) as GetSuccessResponse<Url>;
    if ("errors" in result && result.errors && result.errors.length > 0) {
      return Promise.reject(result);
    }
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

type MutationResponse<
  Url extends keyof paths,
  Method extends "post" | "put" | "patch" | "delete",
> = Method extends "post"
  ? PostSuccessResponse<Url>
  : Method extends "put"
    ? PutSuccessResponse<Url>
    : Method extends "patch"
      ? PatchSuccessResponse<Url>
      : DeleteSuccessResponse<Url>;

export const mutationFetch = async <
  Url extends keyof paths,
  Method extends "post" | "put" | "patch" | "delete",
>(
  url: Url,
  id: string | number,
  method: Method,
  body?: Method extends "post"
    ? PostBody<Url>
    : Method extends "put"
      ? PutBody<Url>
      : Method extends "patch"
        ? PatchBody<Url>
        : undefined,
): Promise<MutationResponse<Url, Method>> => {
  const baseUrl = getBaseUrl();
  let apiUrl = `${baseUrl}${url}`;
  if (id) {
    apiUrl = apiUrl.replace("{id}", id.toString());
  }
  try {
    const rawResult = await fetch(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
      method: method,
      body: body ? JSON.stringify(body) : undefined,
    });
    if (!rawResult.ok) {
      const errorBody = await rawResult.json();
      return Promise.reject(errorBody);
    }
    const result = (await rawResult.json()) as MutationResponse<Url, Method>;
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type QueryConfig<Url extends keyof paths> = Omit<
  UseQueryOptions<GetSuccessResponse<Url>>,
  "queryKey" | "queryFn"
>;

export const useApiQuery = <Url extends keyof paths>(
  url: Url,
  options?: GetOptions<Url>,
  config?: QueryConfig<Url>,
) => {
  return useQuery<GetSuccessResponse<Url>>({
    queryKey: [url, options?.id, options?.params].filter(Boolean),
    queryFn: () => getFetch(url, options),
    ...config,
  });
};

export type InfiniteQueryConfig<Url extends keyof paths> = Omit<
  UseInfiniteQueryOptions<
    GetSuccessResponse<Url>,
    Error,
    InfiniteData<GetSuccessResponse<Url>>,
    GetSuccessResponse<Url>,
    QueryKey,
    number
  >,
  | "queryKey"
  | "queryFn"
  | "getNextPageParam"
  | "getPreviousPageParam"
  | "initialPageParam"
>;

export const useApiInfiniteQuery = <Url extends keyof paths>(
  url: Url,
  options?: GetOptions<Url>,
  config?: InfiniteQueryConfig<Url>,
) => {
  return useInfiniteQuery<
    GetSuccessResponse<Url>,
    Error,
    InfiniteData<GetSuccessResponse<Url>>,
    QueryKey,
    number
  >({
    queryKey: [url, options?.id, options?.params].filter(Boolean),
    queryFn: ({ pageParam = 1 }) =>
      getFetch(url, {
        ...options,
        params: {
          ...options?.params,
          page: pageParam,
        },
      }),
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (
        "meta" in lastPage &&
        lastPage.meta !== null &&
        typeof lastPage.meta === "object" &&
        "page" in lastPage.meta &&
        "pages" in lastPage.meta &&
        typeof lastPage.meta.page === "number" &&
        typeof lastPage.meta.pages === "number" &&
        lastPage.meta.page < lastPage.meta.pages
      ) {
        return lastPage.meta.page + 1;
      }
      return null;
    },
    getPreviousPageParam: (firstPage) => {
      if (
        "meta" in firstPage &&
        firstPage.meta !== null &&
        typeof firstPage.meta === "object" &&
        "page" in firstPage.meta &&
        typeof firstPage.meta.page === "number" &&
        firstPage.meta.page > 1
      ) {
        return firstPage.meta.page - 1;
      }
      return null;
    },
    enabled: true,
    ...config,
  });
};

export const useApiMutation = <
  Url extends keyof paths,
  Method extends "post" | "put" | "patch" | "delete",
>(
  url: Url,
  id: Method extends "post" ? undefined : string | number,
  method: Method,
  options?: Omit<
    MutationOptions<
      MutationResponse<Url, Method>,
      Error,
      Method extends "post"
        ? PostBody<Url>
        : Method extends "put"
          ? PutBody<Url>
          : Method extends "patch"
            ? PatchBody<Url>
            : undefined,
      unknown
    >,
    "mutationFn"
  >,
) => {
  return useMutation<
    MutationResponse<Url, Method>,
    Error,
    Method extends "post"
      ? PostBody<Url>
      : Method extends "put"
        ? PutBody<Url>
        : Method extends "patch"
          ? PatchBody<Url>
          : undefined
  >({
    mutationFn: (
      variables: Method extends "post"
        ? PostBody<Url>
        : Method extends "put"
          ? PutBody<Url>
          : Method extends "patch"
            ? PatchBody<Url>
            : undefined,
    ) => mutationFetch(url, id ?? "", method, variables),
    ...options,
  });
};
