import { useEstimateQuery } from "@/hooks/useEstimateQuery";

import { ProjectSummaryCard } from "../projects/ProjectSummaryCard";

export const EstimateShow = ({ estimateId }: { estimateId: string }) => {
  const { data: estimate } = useEstimateQuery({ estimateId });
  if (!estimate) return null;

  const { projectId } = estimate;

  return (
    <div className="space-y-3 p-3">
      <div className="grid grid-cols-[2fr_1fr] gap-3">
        <ProjectSummaryCard projectId={`${projectId}`} />
        <div className="flex h-full flex-col gap-3">
          {/* <ProjectBlockers projectId={projectId} /> */}
          {/* <CustomerNotesCard notes={customerNotes} /> */}
        </div>
      </div>
      <div className="grid grid-cols-[1fr_3fr] gap-3">
        {/* <TotalsCard totals={totals} /> */}
        {/* <MarginCalculatorCard
          estimateId={estimateId}
          margin={margin}
          crewCommitments={crewCommitments}
        /> */}
      </div>
      <div className="flex flex-col gap-3">{/* <EstimateArea /> */}</div>
    </div>
  );
};
