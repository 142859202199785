import { Toaster } from "sonner";

import ErrorBoundary from "@/components/ErrorBoundary";
import { QueryProvider } from "@/providers/QueryProvider";

type RootProviderProps = {
  children: React.ReactNode;
  fallback?: (error: Error | null) => React.ReactNode;
};

export const RootProvider: React.FC<RootProviderProps> = ({
  children,
  fallback,
}) => {
  return (
    <QueryProvider enableDevtools>
      <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>
      <Toaster
        position="top-right"
        richColors
        toastOptions={{
          classNames: {
            toast: "bg-white",
            title: "font-sans",
            description: "font-sans",
            error: "bg-red-50 text-alert",
            warning: "bg-yellow-50 text-warning",
            success: "bg-green-50 text-success",
          },
        }}
      />
    </QueryProvider>
  );
};
