import type { WeatherReport } from "@/types/index";

export const WeatherDayIcon: React.FC<{
  weatherDay?: WeatherReport | null;
}> = ({ weatherDay }) => {
  if (!weatherDay) return null;
  if (weatherDay.icon) {
    return (
      <img
        src={weatherDay.icon.url}
        data-controller="tooltip"
        data-tippy-content={`<div>${weatherDay.description}</div><div>${weatherDay.tempMin}°/${weatherDay.tempMax}°`}
        className="h-8 w-8 shrink-0"
      />
    );
  }
  if (weatherDay.tempMin && weatherDay.tempMax) {
    return (
      <div
        className="flex items-center gap-1"
        data-controller="tooltip"
        data-tippy-content={weatherDay.description}
      >
        <span>{weatherDay.tempMin}°</span>
        <span>{weatherDay.tempMax}°</span>
      </div>
    );
  }
};
