import { SprayCanIcon, UserVoiceIcon } from "@/components/ui/Icons";
import useRouter from "@/hooks/useRouter";
import type { CraftworkersAvailabilityDay } from "@/types";
import { clsxm } from "@/utils/clsxm";
import {
  getEnglishSpeakers,
  getEnglishSpeakersString,
  getSprayers,
  getSprayersString,
} from "@/utils/craftworkers";

type CraftworkerAvailabilityProps = {
  craftworkersAvailabilityDay: CraftworkersAvailabilityDay;
};

export const CraftworkerAvailability: React.FC<
  CraftworkerAvailabilityProps
> = ({ craftworkersAvailabilityDay }) => {
  const {
    date,
    available,
    staffedPercentage,
    availableCraftworkers,
    slots,
    total,
  } = craftworkersAvailabilityDay;
  const { params } = useRouter();
  const { style } = params;

  const openCraftworkerOpeningsModal = () => {
    Turbo.visit(
      `/calendar_events?craftworker_openings=true&modal=true&start=${date}&end=${date}`,
      {
        frame: "modal",
      },
    );
  };

  let progressColor;

  if (slots > total) {
    progressColor = "bg-alert";
  } else if (staffedPercentage > 0 && staffedPercentage < 33) {
    progressColor = "bg-alert";
  } else if (staffedPercentage >= 33 && staffedPercentage < 66) {
    progressColor = "bg-orange-500";
  } else if (staffedPercentage >= 66 && staffedPercentage < 100) {
    progressColor = "bg-warning";
  } else if (staffedPercentage >= 100) {
    progressColor = "bg-success";
  } else {
    progressColor = "bg-transparent";
  }

  const englishSpeakers = getEnglishSpeakers(availableCraftworkers ?? []);
  const englishSpeakersString = getEnglishSpeakersString(
    availableCraftworkers ?? [],
  );

  const sprayers = getSprayers(availableCraftworkers ?? []);
  const sprayersString = getSprayersString(availableCraftworkers ?? []);

  return (
    <div className="flex w-full flex-col items-center justify-center gap-1 text-sm font-normal">
      <div className="flex w-full items-center justify-between gap-1 px-2">
        {available > 0 ? (
          <button
            onClick={openCraftworkerOpeningsModal}
            className={clsxm(
              "flex h-10 w-full cursor-pointer items-center justify-center",
              style === "compact" && "h-8",
            )}
          >
            <div
              className={clsxm(
                "flex w-full items-center justify-center font-bold text-sm leading-none",
                style === "compact" && "text-xs",
              )}
            >
              {available} Available
            </div>
          </button>
        ) : (
          <div
            className={clsxm(
              "flex h-10 w-full items-center justify-center font-bold text-sm leading-none text-gray-400 transition-all",
              style === "compact" && "h-8 text-xs",
            )}
          >
            {total === slots && "Fully Staffed"}
            {total > slots && `${total - slots} Overstaffed`}
            {total < slots &&
              available === 0 &&
              `${slots - total} Overcommitted`}
          </div>
        )}
        <div className="flex items-center gap-1">
          {englishSpeakers.length > 0 && (
            <span
              data-controller="tooltip"
              data-tippy-content={englishSpeakersString + " speak english"}
              className="font-sm flex cursor-default items-center gap-1 rounded bg-gray-50 p-1"
            >
              <UserVoiceIcon />
              <span className="text-xs">{englishSpeakers.length}</span>
            </span>
          )}
          {sprayers.length > 0 && (
            <span
              data-controller="tooltip"
              data-tippy-content={sprayersString + " can spray"}
              className="font-sm flex cursor-default items-center gap-1 rounded bg-gray-50 p-1"
            >
              <SprayCanIcon />
              <span className="text-xs">{sprayers.length}</span>
            </span>
          )}
        </div>
      </div>
      <div className="h-1 w-full bg-gray-200">
        <span
          style={{ width: `${staffedPercentage}%` }}
          className={`${progressColor} block h-full rounded`}
        ></span>
      </div>
    </div>
  );
};

export default CraftworkerAvailability;
