import type { CraftworkersAvailability } from "@/types";
import type { ConstructReactQueryConfig } from "@/utils/constructReactQuery";
import { constructReactQuery } from "@/utils/constructReactQuery";

type CraftworkerAvailabilityQueryProps = {
  start: string;
  end: string;
};

export const useCraftworkerAvailabilityQuery = (
  { start, end }: CraftworkerAvailabilityQueryProps,
  config?: ConstructReactQueryConfig<CraftworkersAvailability>,
) => {
  const url = `/api/calendars/craftworkers/availability?start=${start}&end=${end}`;
  return constructReactQuery<CraftworkersAvailability>(url, config);
};
