import { ChevronExpandY } from "@craftworkco/nucleo-icons";
import { format, isAfter, isSameDay, startOfDay, sub } from "date-fns";

import useRouter from "@/hooks/useRouter";
import useTasks from "@/hooks/useTasks";

import { AccountManagerSelect } from "../craftworkers/AccountManagerSelect";
import { Sidebar } from "../layouts/SidebarLayout";
import { ListGroupLabel } from "../list/ListGroupLabel";
import Button from "../ui/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/Collapsible";
import { CheckinListItem } from "./CheckinListItem";

const ListFilters = () => {
  const { updateParam, updateParamTurbo, params } = useRouter();
  const selectedManagerId = params.managerId;

  return (
    <div className="flex justify-between p-2">
      <AccountManagerSelect
        selected={selectedManagerId}
        onSelect={(value) => {
          updateParam("managerId", value === "all" ? null : value);
          if (params.projectId) {
            updateParamTurbo("projectId", null);
          }
        }}
      />
    </div>
  );
};

const CheckinList = () => {
  const { params } = useRouter();
  const managerId = params.managerId;

  const {
    data: checkinData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useTasks({
    task_type: ["check_in"],
    assignee_id: [managerId].filter(Boolean),
    completed: false,
    overdue: false,
    limit: 100,
  });
  const checkins = checkinData?.pages?.flatMap((page) => page.data);

  const { data: overdueCheckinData } = useTasks({
    task_type: ["check_in"],
    assignee_id: [managerId].filter(Boolean),
    overdue: true,
    limit: 300,
  });
  const overdueCheckins = overdueCheckinData?.pages?.flatMap(
    (page) => page.data,
  );

  const { data: completedCheckinData } = useTasks({
    task_type: ["check_in"],
    assignee_id: [managerId].filter(Boolean),
    completed: true,
    completed_on: {
      start: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd") as never, // this is an annoying thing about the openapi spec and generated types
      end: format(new Date(), "yyyy-MM-dd") as never,
    },
  });
  const completedCheckins = completedCheckinData?.pages?.flatMap(
    (page) => page.data,
  );
  const todaysCheckins = checkins?.filter((checkin) => {
    const dueAt = startOfDay(checkin.dueAt || "");
    const today = new Date();
    return isSameDay(dueAt, today);
  });
  const futureCheckinsGroupedByDate = checkins
    ?.map((checkin) => ({
      ...checkin,
      dueAt: startOfDay(checkin.dueAt || "").toISOString(),
    }))
    ?.filter((checkin) => {
      const dueAt = new Date(checkin.dueAt || "");
      const today = new Date();
      return isAfter(dueAt, today);
    })
    .sort((a, b) => {
      const dueAtA = new Date(a.dueAt || "");
      const dueAtB = new Date(b.dueAt || "");
      return dueAtA.getTime() - dueAtB.getTime();
    })
    .reduce(
      (acc, checkin) => {
        const date = checkin.dueAt;
        if (!date) return acc;
        acc[date] = [...(acc[date] || []), checkin];
        return acc;
      },
      {} as Record<string, typeof checkins>,
    );

  return (
    <Sidebar className="no-scrollbar divide-y">
      <ListFilters />
      {completedCheckins && completedCheckins.length > 0 && (
        <Collapsible>
          <CollapsibleTrigger className="sticky top-0 w-full">
            <ListGroupLabel
              label={`Completed Recently (${completedCheckins?.length ?? 0})`}
              icon={<ChevronExpandY />}
            />
          </CollapsibleTrigger>
          <CollapsibleContent>
            {completedCheckins?.map((checkin) => (
              <CheckinListItem key={checkin.prefixId} checkin={checkin} />
            ))}
          </CollapsibleContent>
        </Collapsible>
      )}
      {overdueCheckins && overdueCheckins.length > 0 && (
        <Collapsible defaultOpen>
          <CollapsibleTrigger className="sticky top-0 w-full">
            <ListGroupLabel
              label={`Past Due (${overdueCheckins?.length ?? 0})`}
              icon={<ChevronExpandY />}
            />
          </CollapsibleTrigger>
          <CollapsibleContent>
            {overdueCheckins.map((checkin) => (
              <CheckinListItem key={checkin.prefixId} checkin={checkin} />
            ))}
          </CollapsibleContent>
        </Collapsible>
      )}
      {todaysCheckins && todaysCheckins.length > 0 && (
        <Collapsible defaultOpen>
          <CollapsibleTrigger className="sticky top-0 w-full">
            <ListGroupLabel label="Today" icon={<ChevronExpandY />} />
          </CollapsibleTrigger>
          <CollapsibleContent>
            {todaysCheckins?.map((checkin) => (
              <CheckinListItem key={checkin.prefixId} checkin={checkin} />
            ))}
          </CollapsibleContent>
        </Collapsible>
      )}
      {futureCheckinsGroupedByDate &&
        Object.keys(futureCheckinsGroupedByDate).length > 0 &&
        Object.entries(futureCheckinsGroupedByDate).map(([date, checkins]) => (
          <Collapsible key={date} defaultOpen>
            <CollapsibleTrigger className="sticky top-0 w-full">
              <ListGroupLabel
                label={format(new Date(date), "EEEE, MMM d")}
                icon={<ChevronExpandY />}
              />
            </CollapsibleTrigger>
            <CollapsibleContent>
              {checkins.map((checkin) => (
                <CheckinListItem key={checkin.prefixId} checkin={checkin} />
              ))}
            </CollapsibleContent>
          </Collapsible>
        ))}
      {hasNextPage && (
        <Button
          variant="ghost"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          className="w-full font-sans"
        >
          {isFetchingNextPage ? "Loading..." : "Load More"}
        </Button>
      )}
    </Sidebar>
  );
};

export default CheckinList;
