import { MoneyBill } from "@craftworkco/nucleo-icons";

import useEstimates from "@/hooks/useEstimates";
import type { ProjectType } from "@/types";
import { formatAsCurrency } from "@/utils/formatAsCurrency";

import type { EstimateStatus } from "../estimates/EstimateStatusBadge";
import EstimateStatusBadge from "../estimates/EstimateStatusBadge";
import ProjectTypeBadge from "../ProjectTypeBadge";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/Accordion";
import { Badge } from "../ui/Badge";
import { Skeleton } from "../ui/Skeleton";

const ProjectEstimates = ({ projectId }: { projectId?: string }) => {
  const { data, isLoading } = useEstimates(
    {
      project_id: [projectId],
    },
    {
      enabled: Boolean(projectId),
    },
  );
  const estimates = data?.data;

  return (
    <Accordion
      collapsible
      className="w-full rounded-lg border bg-white text-gray-900"
      type="single"
      defaultValue="estimates"
    >
      <AccordionItem value="estimates">
        <AccordionTrigger className="px-4 py-5">
          <div className="flex w-full items-center justify-between gap-2 pr-2">
            <div className="flex items-center gap-2">
              <MoneyBill size={20} />
              <span>Estimates</span>
            </div>
            <Badge variant="outline" className="px-1.5">
              {estimates?.length}
            </Badge>
          </div>
        </AccordionTrigger>
        <AccordionContent className="px-2">
          {isLoading ? (
            <Skeleton className="h-10 w-full" />
          ) : (
            estimates?.map((est) => (
              <a
                href={`/projects/${projectId}/estimates`}
                key={est.prefixId}
                className="flex items-center justify-between gap-2 rounded-md px-2 py-3 hover:bg-gray-100"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center gap-2">
                  <ProjectTypeBadge
                    projectType={est.projectType as ProjectType}
                    className="w-6"
                    iconClassName="block text-white"
                  />
                  <span>{est.accountName}</span>
                </div>
                <div className="flex items-center gap-2">
                  {est.status && (
                    <EstimateStatusBadge
                      status={est.status as EstimateStatus}
                    />
                  )}

                  <span>{est.price && formatAsCurrency(est.price)}</span>
                </div>
              </a>
            ))
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default ProjectEstimates;
