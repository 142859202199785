import { useCallback, useEffect } from "react";
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";

import { getWeekStartAndEnd } from "@/utils/dates";

import { useLocalStorage } from "./useLocalStorage";
import useRouter from "./useRouter";

export type CalendarViewSize = "day" | "week" | "month" | "year";
export type CalendarViewStyle = "detailed" | "compact" | "default";
export type CalendarViewState = {
  viewSize: CalendarViewSize;
  showWeekends: boolean;
  startDate: string;
  endDate: string;
  style: CalendarViewStyle;
};
const calendarViewStateKeys = [
  "viewSize",
  "showWeekends",
  "startDate",
  "endDate",
  "style",
] as const;
type CalendarViewStateKey = (typeof calendarViewStateKeys)[number];

export const DAYS_OF_THE_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
} as const;

type CalendarSettingsProps = {
  calendarName: string;
  defaultViewState: CalendarViewState;
};

export const useCalendarSettings = ({
  calendarName,
  defaultViewState,
}: CalendarSettingsProps) => {
  const { replaceParams, params, constructParamsUrl } = useRouter();
  const [calendarSettings, setCalendarSettings] =
    useLocalStorage<CalendarViewState>(calendarName, {
      viewSize: params.viewSize || defaultViewState.viewSize,
      showWeekends: params.showWeekends || defaultViewState.showWeekends,
      startDate: params.startDate || defaultViewState.startDate,
      endDate: params.endDate || defaultViewState.endDate,
      style: params.style || defaultViewState.style,
    } as CalendarViewState);

  // these are any params that the page is using which don't belong to the calendar view state
  // but should remain in the url
  const unrelatedParams = Object.entries(params)
    .filter(
      ([key]) => !calendarViewStateKeys.includes(key as CalendarViewStateKey),
    )
    .reduce((acc: Record<string, string>, [key, value]) => {
      acc[key] = `${value}`;
      return acc;
    }, {});

  const syncUrlParams = useCallback(
    (urlParams: CalendarViewState) => {
      const newParams = Object.entries(urlParams).reduce(
        (acc, [key, value]) => {
          acc[key] = `${value}`;
          return acc;
        },
        {} as Record<string, string>,
      );
      replaceParams({
        ...newParams,
        ...unrelatedParams,
      });
    },
    [unrelatedParams],
  );

  useEffect(() => {
    const paramsReady =
      window.location.search.length > 0 && Object.keys(params).length > 0;
    const paramsEmpty = window.location.search.length === 0;
    const paramsMatch =
      paramsReady &&
      Object.entries(calendarSettings).reduce((acc, [key, value]) => {
        if (
          params[key as keyof CalendarViewState]?.toString() !==
          value.toString()
        ) {
          return false;
        }
        if (acc === false) {
          return false;
        }
        return true;
      }, true);
    if (paramsEmpty || (paramsReady && !paramsMatch)) {
      syncUrlParams(calendarSettings);
    }
  }, [params, calendarSettings]);

  const constructNewSettings = (params: Partial<CalendarViewState>) => {
    return {
      ...calendarSettings,
      ...params,
      ...(!(params.startDate && params.endDate)
        ? getDateBoundsForViewSize(
            params.viewSize ?? calendarSettings.viewSize,
            params.startDate ?? calendarSettings.startDate,
            params.showWeekends ?? calendarSettings.showWeekends,
          )
        : {}),
    };
  };

  const updateCalendarSettings = (updates: Partial<CalendarViewState>) => {
    const newSettings = constructNewSettings(updates);
    setCalendarSettings(newSettings);
    syncUrlParams(newSettings);
  };

  const goToPreviousCycle = (viewSize: CalendarViewSize) => {
    return updateCalendarSettings({
      startDate: getPreviousStartDate(viewSize, calendarSettings.startDate),
    });
  };

  const goToNextCycle = (viewSize: CalendarViewSize) => {
    return updateCalendarSettings({
      startDate: getNextStartDate(viewSize, calendarSettings.startDate),
    });
  };

  // These functions are intended to work with turbo prefetching, if that will work on that page
  // in an all react-query world, this is less important
  const getPreviousCycleUrl = (viewSize: CalendarViewSize) => {
    const newSettings = constructNewSettings({
      viewSize,
      startDate: getPreviousStartDate(viewSize, calendarSettings.startDate),
    });
    const newParams = Object.entries(newSettings).reduce(
      (acc, [key, value]) => {
        acc[key] = `${value}`;
        return acc;
      },
      {} as Record<string, string>,
    );
    return constructParamsUrl(newParams);
  };

  const getNextCycleUrl = (viewSize: CalendarViewSize) => {
    const newSettings = constructNewSettings({
      viewSize,
      startDate: getNextStartDate(viewSize, calendarSettings.startDate),
    });
    const newParams = Object.entries(newSettings).reduce(
      (acc, [key, value]) => {
        acc[key] = `${value}`;
        return acc;
      },
      {} as Record<string, string>,
    );
    return constructParamsUrl(newParams);
  };

  return {
    calendarSettings,
    setCalendarSettings,
    setView: (viewSize: CalendarViewSize) =>
      updateCalendarSettings({ viewSize }),
    setStyle: (style: "detailed" | "compact" | "default") =>
      updateCalendarSettings({ style }),
    setDate: (date: string | Date, viewSize: CalendarViewSize) =>
      updateCalendarSettings({
        startDate: date.toString(),
        viewSize,
      }),
    toggleWeekends: () =>
      updateCalendarSettings({
        showWeekends: !calendarSettings.showWeekends,
        ...getWeekStartAndEnd(
          calendarSettings.startDate,
          !calendarSettings.showWeekends,
        ),
      }),
    goToPreviousWeek: () => goToPreviousCycle("week"),
    goToNextWeek: () => goToNextCycle("week"),
    goToToday: () =>
      updateCalendarSettings({
        startDate: new Date().toISOString(),
      }),
    goToPreviousMonth: () => goToPreviousCycle("month"),
    goToNextMonth: () => goToNextCycle("month"),
    goToPreviousYear: () => goToPreviousCycle("year"),
    goToNextYear: () => goToNextCycle("year"),
    goToPreviousCycle,
    goToNextCycle,
    getPreviousCycleUrl,
    getNextCycleUrl,
  };
};

const getNextStartDate = (
  viewSize: CalendarViewSize,
  currentStartDate: string | Date,
) => {
  switch (viewSize) {
    case "day":
      return addDays(currentStartDate, 1).toISOString();
    case "week":
      return addWeeks(currentStartDate, 1).toISOString();
    case "month":
      return addMonths(currentStartDate, 1).toISOString();
    case "year":
      return addYears(currentStartDate, 1).toISOString();
  }
};

const getPreviousStartDate = (
  viewSize: CalendarViewSize,
  currentStartDate: string | Date,
) => {
  switch (viewSize) {
    case "day":
      return subDays(currentStartDate, 1).toISOString();
    case "week":
      return subWeeks(currentStartDate, 1).toISOString();
    case "month":
      return subMonths(currentStartDate, 1).toISOString();
    case "year":
      return subYears(currentStartDate, 1).toISOString();
  }
};

export const getDateBoundsForViewSize = (
  viewSize: CalendarViewSize,
  currentStartDate: string | Date,
  showWeekends?: boolean,
) => {
  switch (viewSize) {
    case "day":
      return {
        startDate: startOfDay(new Date(currentStartDate)).toISOString(),
        endDate: endOfDay(new Date(currentStartDate)).toISOString(),
      };
    case "week": {
      return getWeekStartAndEnd(
        currentStartDate.toString(),
        showWeekends ?? false,
      );
    }
    case "month":
      return {
        startDate: startOfMonth(new Date(currentStartDate)).toISOString(),
        endDate: endOfMonth(new Date(currentStartDate)).toISOString(),
      };
    case "year":
      return {
        startDate: startOfYear(new Date(currentStartDate)).toISOString(),
        endDate: endOfYear(new Date(currentStartDate)).toISOString(),
      };
  }
};

export const getViewSizeForFullCalendarView: (
  view: string,
) => CalendarViewSize = (view: string) => {
  switch (view) {
    case "dayGridMonth":
      return "month";
    case "dayGridWeek":
      return "week";
    case "timelineDay":
      return "day";
    default:
      return "week";
  }
};
