import {
  Bell,
  Calendar,
  ChevronRight,
  ClipboardCheck,
  Clock,
  Discount,
  House7,
  HouseBolt,
  Msgs,
  TriangleWarning,
  Users5,
  WrenchScrewdriver,
} from "@craftworkco/nucleo-icons";
import * as HeroIcons from "@heroicons/react/24/outline";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";

import UserMenu from "@/components/UserMenu";
import useRouter from "@/hooks/useRouter";
import type { CustomView, RecentView } from "@/types";

import { Badge } from "../Badge";
import { Logo } from "../Logo";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarRail,
  useSidebar,
} from "../Sidebar";

const formatIconName = (icon: string) => {
  const pascalCase = icon
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
  return pascalCase;
};

type Link = {
  name: string;
  icon?: React.ReactNode;
  url?: string;
  engOnly?: boolean;
  links?: Link[];
  match?: string;
};

const adminLinks: Link[] = [
  {
    name: "Craftworkers",
    icon: <WrenchScrewdriver />,
    url: "/craftworkers/list",
  },
  { name: "Discounts", icon: <Discount />, url: "/discounts" },
];

const Header = ({ toggleSidebar }: { toggleSidebar: () => void }) => {
  return (
    <SidebarHeader>
      <div
        className="mb-4 h-7 shrink-0 cursor-pointer items-center overflow-hidden px-1 hover:opacity-80"
        onClick={toggleSidebar}
      >
        <Logo size="sm" />
      </div>
    </SidebarHeader>
  );
};

type MainNavProps = {
  engOnly: boolean;
  currentPath: string;
  unreadCount: number;
  recentProjectViews?: RecentView[];
};

const mainNavLinks: Link[] = [
  {
    name: "Dashboard",
    icon: <House7 />,
    url: "/",
    links: [
      { name: "Home", url: "/" },
      { name: "Growth", url: "/sales" },
      {
        name: "Analytics",
        url: "/analytics",
      },
      { name: "Map", url: "/map" },
    ],
  },
  { name: "Customers", icon: <Users5 />, url: "/customers" },
  { name: "Projects", icon: <ClipboardCheck />, url: "/projects/list" },
  {
    name: "Sales",
    icon: <HouseBolt />,
    links: [
      {
        name: "Check-ins",
        icon: <Clock />,
        url: "/sales/checkins",
      },
      {
        name: "Estimates",
        icon: <TriangleWarning />,
        url: "/sales/estimates_review",
        engOnly: true,
      },
    ],
  },
  { name: "Calendar", icon: <Calendar />, url: "/calendars" },
  { name: "Messages", icon: <Msgs />, url: "/conversations" },
  { name: "Notifications", icon: <Bell />, url: "/notifications" },
];

const MainNav: React.FC<MainNavProps> = ({
  engOnly,
  currentPath,
  unreadCount,
  recentProjectViews,
}) => {
  const handleClick = (e: React.MouseEvent, link: Link) => {
    const target = e.currentTarget as HTMLElement;
    const isCollapsedIcon = target.closest('[data-collapsible="icon"]');
    if (
      link.url &&
      isCollapsedIcon &&
      target.getAttribute("aria-expanded") === "false"
    ) {
      window.location.href = link.url;
    }
  };

  const allProjectsLink = {
    name: "All Projects",
    url: "/projects/list",
    match: "/projects",
  };

  const recentProjectLinks = recentProjectViews?.map((view) => ({
    name: view.name,
    url: view.url,
  })) || [allProjectsLink];

  const links = [...mainNavLinks];
  const projectsLink = links.find((link) => link.name === "Projects");

  if (projectsLink && recentProjectLinks.length > 0) {
    projectsLink.links = [allProjectsLink, ...recentProjectLinks];
  }

  return (
    <SidebarMenu>
      {mainNavLinks
        .filter((link) => (link.engOnly ? engOnly : true))
        .map((link, i) =>
          link.links ? (
            <Collapsible
              asChild
              defaultOpen={link.links
                .filter((sublink) => !sublink.engOnly)
                .some(
                  (sublink) =>
                    currentPath === sublink.url ||
                    (sublink.match && currentPath.startsWith(sublink.match)),
                )}
              className="group/collapsible"
              key={i}
            >
              <SidebarMenuItem>
                <CollapsibleTrigger asChild>
                  <SidebarMenuButton
                    tooltip={link.name}
                    onClick={(e) => handleClick(e, link)}
                  >
                    {link.icon}
                    <span>{link.name}</span>
                    <ChevronRight
                      size={12}
                      className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90"
                    />
                  </SidebarMenuButton>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  <SidebarMenuSub>
                    {link.links
                      .filter((sublink) => !sublink.engOnly)
                      .map((sublink, i) => (
                        <SidebarMenuSubItem key={i}>
                          <SidebarMenuSubButton
                            asChild
                            isActive={
                              sublink.match
                                ? currentPath === sublink.match
                                : currentPath === sublink.url
                            }
                            tooltip={sublink.name}
                          >
                            <a href={sublink.url}>
                              {sublink.icon}
                              <span className="truncate">{sublink.name}</span>
                            </a>
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                  </SidebarMenuSub>
                </CollapsibleContent>
              </SidebarMenuItem>
            </Collapsible>
          ) : (
            <SidebarMenuItem key={link.name}>
              <SidebarMenuButton
                asChild
                isActive={currentPath === link.url}
                tooltip={link.name}
              >
                <a href={link.url}>
                  {link.icon}
                  <span className="truncate">{link.name}</span>
                  {link.name === "Inbox" && unreadCount > 0 && (
                    <Badge className="ml-auto bg-red-200 px-1.5 text-red-900">
                      {unreadCount}
                    </Badge>
                  )}
                </a>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ),
        )}
    </SidebarMenu>
  );
};

type FavoritesNavProps = {
  favorites: CustomView[];
  currentPath: string;
};

const FavoritesNav: React.FC<FavoritesNavProps> = ({
  favorites,
  currentPath,
}) => {
  return (
    <>
      <SidebarGroupLabel>
        <a
          href="/custom_views"
          className="cursor-pointer text-current hover:opacity-80 group-data-[collapsible=icon]:hidden"
        >
          Favorites
        </a>
      </SidebarGroupLabel>
      <SidebarMenu>
        {favorites?.map((favorite, i) => {
          const iconName = formatIconName(favorite.icon);
          const IconComponent =
            (HeroIcons as Record<string, React.ComponentType>)[
              `${iconName}Icon`
            ] ?? HeroIcons.QuestionMarkCircleIcon;
          return (
            <SidebarMenuItem key={i}>
              <SidebarMenuButton
                asChild
                isActive={currentPath === favorite.url}
                tooltip={favorite.name}
              >
                <a href={favorite.url}>
                  {IconComponent && <IconComponent />}
                  <span className="truncate">{favorite.name}</span>
                </a>
              </SidebarMenuButton>
            </SidebarMenuItem>
          );
        })}
      </SidebarMenu>
    </>
  );
};

type AdminNavProps = {
  links: Link[];
  currentPath: string;
};

const AdminNav: React.FC<AdminNavProps> = ({ links, currentPath }) => {
  return (
    <>
      <SidebarGroupLabel>
        <span>Admin</span>
      </SidebarGroupLabel>
      <SidebarMenu>
        {links.map((link, i) => (
          <SidebarMenuItem key={i}>
            <SidebarMenuButton
              asChild
              isActive={currentPath === link.url}
              tooltip={link.name}
            >
              <a href={link.url}>
                {link.icon}
                <span className="truncate">{link.name}</span>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
        ))}
      </SidebarMenu>
    </>
  );
};

type AppSidebarProps = {
  engOnly?: boolean;
  favorites?: CustomView[];
  avatarUrl: string;
  userName: string;
  admin?: boolean;
  initialPath: string;
  unreadCount?: number;
  logoutUrl: string;
  recentProjectViews?: RecentView[];
};

const AppSidebar = ({
  engOnly = false,
  favorites = [],
  avatarUrl,
  userName,
  admin = false,
  initialPath,
  unreadCount = 2,
  logoutUrl,
  recentProjectViews,
  ...props
}: React.ComponentProps<typeof Sidebar> & AppSidebarProps) => {
  const { open, toggleSidebar } = useSidebar();
  const { pathname } = useRouter();
  const currentPath = pathname ? pathname : initialPath;

  return (
    <Sidebar collapsible="icon" className="h-full" {...props}>
      <Header toggleSidebar={toggleSidebar} />
      <SidebarContent className="px-2">
        <MainNav
          engOnly={engOnly}
          currentPath={currentPath}
          unreadCount={unreadCount}
          recentProjectViews={recentProjectViews}
        />
        <FavoritesNav favorites={favorites} currentPath={currentPath} />
        <AdminNav links={adminLinks} currentPath={currentPath} />
      </SidebarContent>
      <SidebarFooter>
        <UserMenu
          avatarUrl={avatarUrl}
          userName={userName}
          admin={admin}
          engOnly={engOnly}
          collapsed={!open}
          logoutUrl={logoutUrl}
        />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
};

export default AppSidebar;
