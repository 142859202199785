import { ChevronLeft, ChevronRight } from "@craftworkco/nucleo-icons";

import { Calendar as DatePicker } from "@/components/ui/Calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/Popover";
import type {
  CalendarViewSize,
  CalendarViewStyle,
} from "@/hooks/useCalendarSettings";
import {
  getDateBoundsForViewSize,
  useCalendarSettings,
} from "@/hooks/useCalendarSettings";
import useRouter from "@/hooks/useRouter";
import Button from "@/ui/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/ui/SelectDropdown";
import { clsxm } from "@/utils/clsxm";
import { formatDate, formatDateRange } from "@/utils/dateFormatter";

type CalendarControlsProps = {
  calendarName: string;
  defaultViewSize: CalendarViewSize;
  allowedViewSizes: CalendarViewSize[];
  className?: string;
};

export const CalendarControls = ({
  calendarName,
  defaultViewSize,
  allowedViewSizes,
  className,
}: CalendarControlsProps) => {
  const { params } = useRouter();
  const {
    setDate,
    setView,
    calendarSettings,
    goToPreviousCycle,
    goToNextCycle,
  } = useCalendarSettings({
    calendarName,
    defaultViewState: {
      ...getDateBoundsForViewSize(
        (params.viewSize as CalendarViewSize) || defaultViewSize,
        params.startDate ? new Date(params.startDate) : new Date(),
        false,
      ),
      viewSize: (params.viewSize as CalendarViewSize) || defaultViewSize,
      showWeekends: false,
      style: (params.style as CalendarViewStyle) || "detailed",
    },
  });

  const { startDate, endDate, viewSize } = calendarSettings;

  const formattedDateRange =
    viewSize === "day"
      ? formatDate(startDate, {
          month: "short",
          day: "numeric",
        })
      : formatDateRange(startDate, endDate, {
          month: "short",
          day: "numeric",
        });
  return (
    <div className={clsxm("flex items-center gap-2", className)}>
      <Button
        variant="secondary"
        onClick={() => goToPreviousCycle(viewSize)}
        className="h-9 w-9"
      >
        <ChevronLeft className="h-6 w-6" />
      </Button>
      <div className="flex flex-col items-center">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="ghost" className="h-5 px-2">
              <h2 className={clsxm(allowedViewSizes.length > 1 && "text-base")}>
                {formattedDateRange}
              </h2>
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <DatePicker
              mode="single"
              required={false}
              className="rounded-md border-none"
              selected={new Date(startDate)}
              onSelect={(date?: Date) => {
                if (!date) return;
                setDate(date, viewSize);
              }}
            />
          </PopoverContent>
        </Popover>
        {allowedViewSizes.length > 1 && (
          <Select onValueChange={setView} value={viewSize}>
            <SelectTrigger
              className="h-3 w-full justify-center border-none p-0 text-xs shadow-none hover:bg-gray-100"
              noIcon
            >
              <SelectValue placeholder="Select Calendar View" />
            </SelectTrigger>
            <SelectContent>
              {allowedViewSizes.map((viewSize) => (
                <SelectItem key={viewSize} value={viewSize}>
                  {viewSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      </div>
      <Button
        variant="secondary"
        onClick={() => goToNextCycle(viewSize)}
        className="h-9 w-9"
      >
        <ChevronRight className="h-6 w-6" />
      </Button>
    </div>
  );
};
