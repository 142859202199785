import { SidebarLayout } from "@/components/layouts/SidebarLayout";
import { TooltipProvider } from "@/components/ui/Tooltip";
import { RootProvider } from "@/providers/RootProvider";

import { CheckinSidebar } from "./CheckinSidebar";
import { CheckinSummary } from "./CheckinSummary";

export const CheckinsIndex = ({
  conversationHtml,
}: {
  conversationHtml: string;
}) => {
  return (
    <RootProvider>
      <TooltipProvider>
        <SidebarLayout
          sidebar={<CheckinSidebar />}
          main={<CheckinSummary conversationHtml={conversationHtml} />}
        />
      </TooltipProvider>
    </RootProvider>
  );
};
