import { DayPicker } from "react-day-picker";

import { clsxm } from "@/utils/clsxm";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={clsxm("p-3", className)}
      classNames={{
        months: "flex flex-col relative",
        month_caption: "flex justify-center h-7 mx-10 relative items-center",
        weekdays: "flex flex-row",
        weekday: "text-muted-foreground w-8 font-normal text-[0.8rem]",
        month: "gap-y-4 overflow-x-hidden w-full",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium truncate",
        button_next:
          "absolute right-0 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 border rounded-md border-gray-200 text-plum hover:bg-white/80 focus-visible:outline-plum hover:text-gray-800",
        button_previous:
          "absolute left-0 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 border rounded-md border-gray-200 text-plum hover:bg-white/80 focus-visible:outline-plum hover:text-gray-800",
        nav: "flex items-start justify-between absolute w-full",
        month_grid: "mt-4",
        week: "flex w-full mt-2",
        day: "p-0 size-8 text-sm flex-1 flex items-center justify-center has-[button]:hover:!bg-accent rounded-md has-[button]:hover:aria-selected:!bg-primary has-[button]:hover:text-accent-foreground has-[button]:hover:aria-selected:text-primary-foreground",
        day_button:
          "size-8 p-0 font-normal transition-none hover:bg-gray-50 rounded-sm hover:text-inherit aria-selected:opacity-100 focus-visible:outline-plum",
        range_start: "day-range-start rounded-s-md",
        range_end: "day-range-end rounded-e-md",
        selected:
          "bg-primary text-white hover:!bg-primary hover:text-primary focus:bg-primary focus:text-primary-foreground",
        today: "bg-gray-100 text-gray-800 hover:bg-gray-200",
        outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        disabled: "text-muted-foreground opacity-50",
        range_middle:
          "aria-selected:bg-accent hover:aria-selected:!bg-accent rounded-none aria-selected:text-accent-foreground hover:aria-selected:text-accent-foreground",
        hidden: "invisible",
        ...classNames,
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
