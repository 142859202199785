import { ListCheckbox } from "@craftworkco/nucleo-icons";

import CheckinList from "@/components/tasks/CheckinList";
import Button from "@/components/ui/Button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/Sheet";

export const CheckinSidebar = () => {
  return (
    <>
      <div className="hidden h-full w-[300px] overflow-hidden overflow-y-auto bg-white md:flex">
        <CheckinList />
      </div>
      <div className="flex md:hidden">
        <Sheet>
          <SheetTrigger asChild>
            <Button variant="ghost">
              <ListCheckbox />
            </Button>
          </SheetTrigger>

          <SheetContent side="left" className="w-[300px] bg-white p-0">
            <SheetHeader className="p-4">
              <SheetTitle>Checkins</SheetTitle>
            </SheetHeader>
            <CheckinList />
          </SheetContent>
        </Sheet>
      </div>
    </>
  );
};
