import Cookies from 'js-cookie'
import jstz from 'jstz'

// Rails doesn't support every timezone that Intl supports
export function findTimeZone() {
  const oldIntl = window.Intl
  try {
    // @ts-expect-error Intl is not defined in the global scope
    window.Intl = undefined
    const tz = jstz.determine().name()
    window.Intl = oldIntl
    return tz
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    // sometimes (on android) you can't override intl
    return jstz.determine().name()
  }
}

document.addEventListener("turbo:load", () => {
  Cookies.set("browser_time_zone", findTimeZone(), { expires: 365, path: "/", secure: true, sameSite: 'strict' })
})
