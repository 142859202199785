import { ListCheckbox } from "@craftworkco/nucleo-icons";

import useTasks from "@/hooks/useTasks";
import type { GetSuccessResponse } from "@/types/api/util";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/Accordion";
import { Badge } from "../ui/Badge";
import { Skeleton } from "../ui/Skeleton";
import ProjectTaskItem from "./ProjectTaskItem";

const sortByDueDate = (
  a: GetSuccessResponse<"/api/v2/tasks">["data"][number],
  b: GetSuccessResponse<"/api/v2/tasks">["data"][number],
) => new Date(a.dueAt).getTime() - new Date(b.dueAt).getTime();

const ProjectTasks = ({ projectId }: { projectId?: string }) => {
  const { data, isLoading } = useTasks(
    {
      project_id: [projectId],
      limit: 100,
    },
    {
      enabled: Boolean(projectId),
    },
  );
  const tasks = data?.pages.flatMap((page) => page.data);

  return (
    <Accordion
      collapsible
      className="w-full rounded-lg border bg-white text-gray-900"
      type="single"
    >
      <AccordionItem value="tasks">
        <AccordionTrigger className="px-4 py-5">
          <div className="flex w-full items-center justify-between gap-2 pr-2">
            <div className="flex items-center gap-2">
              <ListCheckbox size={20} />
              <span>Tasks</span>
            </div>
            <Badge variant="outline" className="px-1.5">
              {tasks?.length}
            </Badge>
          </div>
        </AccordionTrigger>
        <AccordionContent className="px-2">
          {isLoading ? (
            <div className="flex flex-col gap-2">
              <Skeleton className="h-6 w-full" />
              <Skeleton className="h-6 w-full" />
              <Skeleton className="h-6 w-full" />
            </div>
          ) : (
            tasks
              ?.sort(sortByDueDate)
              .map((task) => (
                <ProjectTaskItem key={task.prefixId} task={task} />
              ))
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default ProjectTasks;
