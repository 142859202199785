import type { Estimate } from "@/types";
import type { ConstructReactQueryConfig } from "@/utils/constructReactQuery";
import { constructReactQuery } from "@/utils/constructReactQuery";

type EstimateQueryProps = {
  estimateId: string;
};

export const useEstimateQuery = (
  { estimateId }: EstimateQueryProps,
  config?: ConstructReactQueryConfig<Estimate>,
) => {
  const url = `/api/v1/estimates/${estimateId}`;
  return constructReactQuery<Estimate>(url, {
    ...config,
    queryKey: ["estimate", estimateId],
  });
};
