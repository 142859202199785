// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useEffect, useMemo, useState } from "react";

type Router = {
  constructParamsUrl: (params: Record<string, string>) => string;
  params: Record<string, string>;
  pathname: string;
  push: (path: string, params?: Record<string, string>) => void;
  replace: (path: string, params?: Record<string, string>) => void;
  replaceParams: (params: Record<string, string>) => void;
  replaceParamsTurbo: (params: Record<string, string>) => void;
  updateParam: (key: string, value: string | null) => void;
  updateParamTurbo: (key: string, value: string | null) => void;
};

const useRouter = (): Router => {
  const [currentUrl, setCurrentUrl] = useState("");
  const [search, setSearch] = useState("");

  const currentParams = useMemo(() => new URLSearchParams(search), [search]);

  const stringifyParams = useCallback(
    (params: Record<string, string> = {}) =>
      new URLSearchParams(params).toString(),
    [],
  );

  const parseParams = useCallback(
    (search: string) =>
      Object.fromEntries(new URLSearchParams(search).entries()),
    [search],
  );

  const updateUrlState = () => {
    setCurrentUrl(window.location.pathname);
    setSearch(window.location.search);
  };

  useEffect(() => {
    updateUrlState();

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (state, title, url) {
      originalPushState.apply(window.history, [state, title, url]);
      window.dispatchEvent(new Event("locationChange"));
    };

    window.history.replaceState = function (state, title, url) {
      originalReplaceState.apply(window.history, [state, title, url]);
      window.dispatchEvent(new Event("locationChange"));
    };

    window.addEventListener("popstate", updateUrlState);
    window.addEventListener("locationChange", updateUrlState);

    return () => {
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
      window.removeEventListener("popstate", updateUrlState);
      window.removeEventListener("locationChange", updateUrlState);
    };
  }, []);

  const navigate = (
    method: "push" | "replace",
    path: string,
    params?: Record<string, string>,
  ) => {
    const newPath = params ? `${path}?${stringifyParams(params)}` : path;
    window.history[`${method}State`]({}, "", newPath);
  };

  const updateParam = useCallback(
    (key: string, value: string | null) => {
      if (value === null) {
        currentParams.delete(key);
      } else {
        currentParams.set(key, value);
      }
      navigate("push", currentUrl, Object.fromEntries(currentParams));
    },
    [currentParams, currentUrl, navigate],
  );

  const updateParamTurbo = useCallback(
    (key: string, value: string | null) => {
      if (value === null) {
        currentParams.delete(key);
      } else {
        currentParams.set(key, value);
      }
      const params = Object.fromEntries(currentParams);
      Turbo.visit(`${currentUrl}?${stringifyParams(params)}`);
    },
    [currentParams, currentUrl],
  );

  const replaceParams = useCallback(
    (params: Record<string, string>) => {
      navigate("replace", currentUrl, params);
    },
    [currentUrl],
  );

  const replaceParamsTurbo = useCallback(
    (params: Record<string, string>) => {
      Turbo.visit(`${currentUrl}?${stringifyParams(params)}`, {
        action: "replace",
      });
    },
    [currentUrl],
  );

  const constructParamsUrl = (params: Record<string, string>) => {
    return `${currentUrl}?${stringifyParams(params)}`;
  };

  return {
    constructParamsUrl,
    params: parseParams(search),
    pathname: currentUrl,
    push: (path: string, params?: Record<string, string>) =>
      navigate("push", path, params),
    replace: (path: string, params?: Record<string, string>) =>
      navigate("replace", path, params),
    replaceParams,
    replaceParamsTurbo,
    updateParam,
    updateParamTurbo,
  };
};

export default useRouter;
